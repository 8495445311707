
import { useState, useEffect, useContext } from "react";
import { AuthContext, serverAddress, appId } from "../App";
import MenuBar from "../components/MenuBar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Dialog } from "@mui/material";
import Claim from "../components/Claim";
import back from "../assets/mynft/back.svg";
import plus from "../assets/mynft/plus.svg";
import "./MyNFT.css";
import nftImg from "../assets/mynft/nft.png";
import backdrop from "../assets/home/backdrop.png";
import textLine from "../assets/home/text-line.svg";
import noticeImg from "../assets/mynft/notice.svg";

export default function MyNFT() {

  const { state } = useContext(AuthContext);
  const [NFTList, setNFTList] = useState([]);
  const [claimOpen, setClaimOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.isLogin === false) {
      navigate("/");
    }
  },[state.isLogin])
  
  useEffect(() => {
    let headers = new Headers();
    headers.append("appId", appId);
    headers.append("accessToken", state.accessToken);
    fetch(serverAddress + "/user/nft", {
      method: "GET",
      headers: headers,
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((res) => {
            if (res.returnCode === "20000") {
              setNFTList(res.data.tokenId);
            }
          });
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  }, [state.accessToken]);

  return (
    <div className="my-nft">

      <img className="text-line left" src={textLine} alt="text-line" />
      <img className="text-line right" src={textLine} alt="text-line" />

      <MenuBar />

      <div className="main">
        <div className="title-bar">
          <Link to="/" className="back"><img src={back} /></Link>
          <div className="page-title">已拥有的重庆限定款数字藏品系列</div>
        </div>
        <div className="grid-container relative">
          <div className="grid grid-cols-2 relative">
            {NFTList.map((id, index) => {
              return (
                <div key={index} className={"card-container item flex flex-col justify-center items-center relative"}>
                  <Link to={"/nft/" + id} className="nft-card">
                    <img className="nft" src={nftImg} />
                  </Link>
                  <div className="card-title">
                    <p className="text-center font-medium name">潮玩数字藏品Horangi</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="notice-container">
            <div className="notice">温馨提示：本数字藏品版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户不得将数字藏品用于任何商业用途。请勿对数字藏品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用。</div>
          </div>
          <img className="notice-img" src={noticeImg} />
        </div>
      </div>

      <Dialog fullScreen open={claimOpen} onClose={() => {setClaimOpen(false)}} sx={{"& .MuiDialog-paper": {backgroundImage: "url("+backdrop+")", overflow:"visible", display:"flex", justifyContent:"center", alignItems:"center"}}}>
        <Claim handleClose={() => {setClaimOpen(false)}} />
      </Dialog>
    </div>
  )
}