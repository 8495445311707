import { portalAddress, AuthContext } from "../App";
import { useContext, useState } from "react";

export default function useLogin(phone, code) {
  const { state, dispatch } = useContext(AuthContext);
  const [result, setResult] = useState();

  const post = async() => {
    try {
      const response = await fetch(
        portalAddress + "/user/login", 
        {
          method: "POST",
          body: JSON.stringify({ phone: phone, code: code }),
        }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        dispatch({
          type: "LOGIN",
          payload: {
            isLogin: true,
            userId: json.data.userId,
            accessToken: json.data.accessToken,
          },
        });
        return true;
      } else {
        console.log(json.message);
        return false;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  return post;
}