import "./Agreement.css";
import subtract from "../assets/agreement/title.png";
import close from "../assets/claim/close.svg";

export default function Agreement({handleClose}) {
  return (
    <div className="agreement">
      <div className="about-title relative flex justify-center mt-10 mb-10">
        <svg onClick={handleClose} className="absolute left-2" width="9" height="22" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.1981 7.56001L8.73003 1.4658C9.08999 1.13037 9.08999 0.586572 8.73003 0.251567C8.37096 -0.0838557 7.7881 -0.0838557 7.42858 0.251567L0.2693 6.93067C-0.0897667 7.26568 -0.0897667 7.80948 0.2693 8.1449C0.297613 8.17132 0.329969 8.19228 0.360977 8.2145C0.366371 8.21995 0.370414 8.22624 0.375807 8.2317L7.36702 14.7544C7.718 15.0819 8.28693 15.0819 8.6379 14.7544C8.98888 14.4269 8.98888 13.8961 8.6379 13.5687L2.1981 7.56001Z" fill="black"/>
        </svg>
        《用户协议》
      </div>
      <div className="agreement-content text-xs text-justify leading-6 mb-8 px-2 overflow-scroll">
        <p>您确认，一旦您以在线点击确认或其他方式签署本协议，即视为您已阅读并同意本协议的所有条款，且知晓并理解本协议所有条款的含义及相应的法律后果，本协议即视为已于您在线点击确认或以其他方式签署本协议之日有效签署，对各方成立并生效。下文中的“我们”指新城商业管理集团有限公司。 </p>
        <p>1.	本次活动数字藏品（即重庆限定款数字藏品）的领取对象为年龄在18周岁以上的中国公民。如您在本站点领取数字藏品，即表示您已经知晓并符合了该领取标准。若您因未符合年龄标准而导致发生的任何违反法律法规的行为，我们不承担任何法律责任，同时本站点保留取消您领取资格的权利。</p>
        <p>2.	数字藏品的拥有者不得将数字藏品用于任何商业用途。每一份数字藏品都是非卖品，无市场价格。数字藏品仅可用于您的个人收藏，学习，研究，欣赏和展示之目的，不可转赠、不可售卖，或以其它任何形式进行转让，亦不可用于任何商业行为，并且严格禁止炒作、场外交易、欺诈等任何非法用途，由此所产生违法及违约责任由用户自行承担。因本次活动中的数字藏品都是非卖品，故不允许领取码用户因个人原因申请退款。</p>
        <p>3.	您需要验证并授权绑定手机号领取以及提取您的数字藏品或使用本站点服务。在您登陆本站点时，您同意我们获取您的手机号；如果您不授予前述同意，您将无法领取或提取数字藏品，并可能无法正常使用本站点的服务。我们将严格遵守相关法律法规与监管要求，依法使用您的信息，并对您的信息保密。</p>
        <p>4.	在您于本站点领取数字藏品后 至 您提取该数字藏品至自己个人区块链钱包之前（具体提取时间段为：2022年02月14日 00:00 - 2022年11月23日20:00:00），手机号是您在本站点绑定账号、查看数字藏品的唯一凭证，请务必妥善保管好您的手机号。如发现有任何恶意利用多个手机号或者其他不正当行为获取数字藏品的行为，您的领取资格和数字藏品将会被取消。</p>
        <p>5.	当您将数字藏品从本站点提取到个人的区块链钱包后，您需妥善保管自己的数字藏品和自己的区块链钱包私钥。提取完成后发生的一切遗失、失窃、无法找回等情形，均由您自行承担。如有使用或提取数字藏品疑问的，您可以通过 nft@cocafe.co 咨询我们。*关于钱包创建和提取方法，请以conflux网站(https://portal.confluxnetwork.org/)说明及本站点后续的公告为准。未来在本站点的服务功能会升级和迭代，具体以我们在本站点的公布为准。</p>
        <p>6.	基于区块链技术的特性，数字藏品具有唯一、不可篡改的特点，数字藏品中的数字内容，铸造时间等信息无法篡改。在您获得数字藏品后，您与数字藏品的所有权等权利关系将通过智能合约记录在区块数据中。</p>
        <p>7.	您理解并同意，您领取数字藏品后，只拥有该数字藏品本身的所有权，不拥有该数字藏品对应的原艺术品的所有权及包括著作权在内的各项知识产权，亦不拥有该数字藏品的知识产权。</p>
        <p>8.	您理解并同意，基于维护网络秩序和安全，如我们发现您存在违法违规或违反本协议约定情形的，我们有权采取同时采取一项或多项措施，包括但不限于： A) 要求您限期纠正违规或违约行为； B) 屏蔽或限制您使用数字藏品； C) 对违法违规信息进行删除或屏蔽，取消您的领取资格或数字藏品对应的相关权益 ；D) 停止或终止向您提供部分或全部服务； E) 其他合理、合法的措施。 我们因任何原因未采取上述任何措施，不代表我们放弃未来采取该等措施的权利。如您的行为使我们遭受损失的，我们还有权要求您赔偿我们的全部损失，包括商誉损失、赔偿金、律师费、诉讼费等。</p>
        <p>9.	您理解并同意，在您获得数字藏品和使用本服务的过程中，可能会遇到不可抗力等风险因素。如出现下列任一情况致使我们无法履行本协议约定的，我们将努力在第一时间与相关单位配合，及时进行修复，但是由此给您或第三方造成的损失，我们不承担责任： A) 在与本服务相关的系统停机维护或升级期间； B) 因自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、法律法规政策变化和政府行为等不可抗力原因； C) 您操作不当或通过非我们授权或认可的方式使用本服务； D) 因病毒、木马、恶意程序攻击网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、电信设备故障、第三方服务瑕疵等原因。</p>
        <p>10. 您理解并同意，鉴于网络服务的特殊性，我们对本服务的持续提供可能受到多种因素的影响，如出现技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化。我们可能随时终止、中断提供服务或变更所提供服务的形式、规格或其他方面，而您不会因此追究我们的责任。</p>
        <p>11. 除上文已经特别指出的以外，站点上的所有内容，包括但不限于文字、图片、档案、资讯、资料、平台架构、平台画面的安排、网页设计等，均由发行方/创作者/合作方/依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。非经我们书面同意，您不得擅自使用、修改、反向编译，复制，公开传播、改变、散布、发行或公开发表、转让、许可、商业利用本站点的相关软件、内容以及我们发行方和合作方享有的知识产权。否则我们有权立即终止本协议并要求您停止违约和/或侵权行为，您应对您的违约或侵权行为给我们或其他权利人造成的损失承担相应赔偿责任。</p>
        <p>12. 为进一步改善用户体验，我们将联合我们的关联方和/或合作方持续更新服务，包括但不限于版本升级、功能升级、技术升级、新增、更新或调整服务内容和功能，本协议可能会随之更新。我们也会根据国家法律法规的变化要求，更新本协议的相关条款。更新的内容将于协议更新公告公布的生效之日起生效。若您不接受变更后的服务内容或功能，或不同意更新后的协议内容，您应立即停止使用相关服务。若您继续使用本协议相关服务的，即视为您已同意我们作出的变更，并自愿接受变更后的协议内容约束。</p>
        <p>13. 本站点由上海咖菲信息科技有限公司提供技术支持。</p>
      </div>
    </div>
  )
}