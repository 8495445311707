import { AuthContext } from "../App";
import { useContext, useState } from "react";
import Login from "./Login";
import { Dialog, Drawer } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import useLogout from "../utils/useLogout";
import "./MenuBar.css";

export default function MenuBar() {
  const { state } = useContext(AuthContext);
  const [loginOpen, setLoginOpen] = useState(false);
  const [btnText, setBtnText] = useState();

  
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const profileOpen = Boolean(profileAnchorEl);
  const handleProfileClick = (event) => {
    if (state.isLogin) {
      setProfileAnchorEl(event.currentTarget);
    } else {
      setLoginOpen(true);
    }
  };
  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const logout = useLogout();
  const handleLogout = () => {
    setProfileAnchorEl(null);
    logout();
  }

  const handleLoginClose = () => {
    setLoginOpen(false);
  }
  
  return (
    <div className="menu-bar flex justify-between">
      <button className="menu-button" onClick={handleClick}> 菜单 </button>
      <button className="profile" onClick={handleProfileClick}> {state.isLogin?"个人中心":"登录"} </button>
      <Drawer
        anchor="bottom"
        open={loginOpen}
        onClose={handleLoginClose}
        sx={{
          "& .MuiDrawer-paper": {
            background: "transparent",
            borderRadius: "10px 10px 0px 0px"
          },
          backdropFilter: "blur(46.2108px)"
        }}
      > 
        <div className="login-container">
          <Login handler={handleLoginClose} toClose={handleLoginClose} />
        </div>
      </Drawer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "22.6666vw",
            backgroundColor:"#FFFFFF"
          },
          "& .MuiMenuItem-root, a": {
            fontSize: "3.7333vw",
            color: "#000000"
          }
        }}
      >
        <MenuItem onClick={handleClose} sx={{ justifyContent: "center" }}><Link to="/">主页</Link></MenuItem>
        <MenuItem onClick={handleClose} sx={{ justifyContent: "center" }}><Link to="/about">关于</Link></MenuItem>
      </Menu>
      <Menu
        id="profile-menu"
        anchorEl={profileAnchorEl}
        open={profileOpen}
        onClose={handleProfileClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          "& .MuiPaper-root" :{
            width: "34vw",
            backgroundColor:"#FFFFFF"
          },
          "& .MuiMenuItem-root, a": {
            fontSize: "3.7333vw",
            color: "#000000"
          }
        }}
      >
        <MenuItem onClick={handleProfileClose} sx={{ justifyContent: "center" }}><Link to="/mynft">查看我的数字藏品</Link></MenuItem>
        <MenuItem onClick={handleLogout} sx={{ justifyContent: "center" }}>退出登录</MenuItem>
      </Menu>
    </div>
  );
}
