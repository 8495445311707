import { AuthContext, serverAddress, appId } from "../App";
import { useContext, useEffect, useState } from "react";
import Login from "../components/Login"
import { Dialog, Drawer } from "@mui/material";
import "./Home.css";
import Claim from "../components/Claim";
import MenuBar from "../components/MenuBar";
import { useNavigate } from "react-router";
import backdrop from "../assets/home/backdrop.png";

import textLine from "../assets/home/text-line.svg";
import headline from "../assets/home/01.svg";
import title1 from "../assets/home/title1.png";
import title2 from "../assets/home/title2.svg";
import nft from "../assets/nft.png";

export default function Home() {

  const { state } = useContext(AuthContext);
  const [loginOpen, setLoginOpen] = useState(false);
  const [claimOpen, setClaimOpen] = useState(false);
  const [claimResult, setClaimResult] = useState(false);
  const navigate = useNavigate();

  const handleLoginClose = () => {
    setLoginOpen(false);
  }
  const handleClaimClose = () => {
    setClaimOpen(false);
  }

  const continueClaim = () => {
    handleLoginClose();
    setClaimOpen(true);
  }

  const [claimed, setClaimed] = useState();
  useEffect(() => {
    if (state.isLogin) {
      let headers = new Headers();
      headers.append("appId", appId);
      headers.append("accessToken", state.accessToken);
      fetch(serverAddress + "/user/nft", {
        method: "GET",
        headers: headers,
      })
        .then((res) => {
          if (res.status === 200) {
            res.json().then((res) => {
              if (res.returnCode === "20000") {
                if (res.data.tokenId.length > 0) {
                  setClaimed(true);
                }
              }
            });
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
  },[state.isLogin])

  const claim = () => {
    if (state.isLogin) {
      if (!claimed) {
        setClaimOpen(true);
      } else {
        navigate("/mynft");
      }
    } else {
      setLoginOpen(true);
    }
  }

  return (
    <div className="home">

      <img className="text-line left" src={textLine} alt="text-line" />
      <img className="text-line right" src={textLine} alt="text-line" /> 

      <MenuBar />

      <div className="main">
        <img className="headline" src={headline} alt="headline"/>
        <div className="nft-container">
          <div className="flex flex-col items-center">
            <img className="title1" src={title1} alt="title" />
            <img className="title2" src={title2} alt="title" />
          </div>
          <img className="nft-image" src={nft} alt="nft" />
          <div className="button-container relative">
            <div className="button-shadow"></div>
            <button className="button-claim" onClick={claim}>
              {state.isLogin && claimed ? "查看我的数字藏品": "立即领取"}
            </button>
          </div>
          <div className="get-more" onClick={()=>{setClaimOpen(true)}}>{state.isLogin && claimed && "获取更多"}</div>
          <div className="notice-container">
            <div className="notice">温馨提示：本数字藏品版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户不得将数字藏品用于任何商业用途。请勿对数字藏品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用。</div>
          </div>
        </div>
      </div>

      <Drawer
        anchor="bottom"
        open={loginOpen}
        onClose={handleLoginClose}
        sx={{
          "& .MuiDrawer-paper": {
            background: "transparent",
            borderRadius: "10px 10px 0px 0px"
          },
          backdropFilter: "blur(46.2108px)"
        }}
      > 
        <div className="login-container">
          <Login handler={continueClaim} toClose={handleLoginClose}/>
        </div>
      </Drawer>

      <Dialog fullScreen open={claimOpen} onClose={handleClaimClose} sx={{"& .MuiDialog-paper": {backgroundImage: "url("+backdrop+")", backgroundSize: "cover", overflow:"visible", display:"flex", justifyContent:"center", alignItems:"center"}}}>
        <Claim handleClose={handleClaimClose}/>
      </Dialog>
    </div>
  )
}