import { useEffect, useContext, useState } from "react";
import { serverAddress, AuthContext, appId } from "../App";
import { useParams, useNavigate } from "react-router-dom";
import MenuBar from "../components/MenuBar";
import textLine from "../assets/home/text-line.svg";
import video from "../assets/nft-video.mp4";
import "./Detail.css";

export default function Detail() {
  const { state } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (state.isLogin === false) {
      navigate("/");
    }
  },[state.isLogin])
  const [detail, setDetail] = useState({});
  const params = useParams();

  const getSubStr = (str) => {
    var subStr1 = str.substr(0, 7);
    var subStr2 = str.substr(str.length - 17, 17);
    var subStr = subStr1 + "..." + subStr2;
    return subStr;
  };
  const [visible, setVisible] = useState();

  useEffect(async () => {
    if (state.accessToken) {
      let headers = new Headers();
      headers.append("appId", appId);
      headers.append("accessToken", state.accessToken);
      try {
        const response = await fetch(serverAddress + "/nft/detail/" + params.id, {
          method: "GET",
          headers: headers,
        });
        const json = await response.json();
        if (json.returnCode === "20000") {
          console.log(json.data.updateTime)
          setDetail({
            updateTime: json.data.updateTime,
            owner: getSubStr(json.data.owner),
          });
        } else {
          console.log(json.message);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  },[state.accessToken])
  return (
    <div className="detail">
      <img className="text-line left" src={textLine} alt="text-line" />
      <img className="text-line right" src={textLine} alt="text-line" />
      <MenuBar />
      <div className="detail-container">
        <video className="nft-video" autoPlay loop muted playsInline> <source src={video} type="video/mp4"/> </video>
        <div className="nft-information">
          <div className="information-content">
            <div className="information-item ">
              <div className="label">发行方：</div>
            </div>
            <div className="information-item">
              <div className="label">创建时间：</div>
              <div className="value">{detail.updateTime}</div>
            </div>
            <div className="information-item">
              <div className="label">创作作者：咖菲科技</div>
            </div>
            <div className="information-item">
              <div className="label">所有者地址：</div>
              <div className="value">{detail.owner}</div>
            </div>
          </div>
        </div>
        <div className="notice-container">
          <div className="notice">温馨提示：本数字藏品版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户不得将数字藏品用于任何商业用途。请勿对数字藏品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用。</div>
        </div>
        <div className="poster-generate">
          <div className="button-shadow"></div>
          <button className="button-generate" onClick={()=>{navigate("/poster/" + params.id)}}>
            生成海报
          </button>
        </div>
      </div>
    </div>
  )
}