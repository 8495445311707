import { useEffect, useState } from "react";
import useLogin from "../utils/useLogin";
import useSMS from "../utils/useSMS";
import Checkbox from '@mui/material/Checkbox';
import Agreement from "../components/Agreement";
import { Drawer } from "@mui/material";
import backdrop from "../assets/agreement/agreement-background.svg";
import close from "../assets/login/close.svg";

import "./Login.css";

export default function Login({ handler, toClose }) {

  const [phone, setPhone] = useState();
  const [code, setCode] = useState();

  const [phoneValidate, setPhoneValidate] = useState(false);
  const [codeValidate, setCodeValidate] = useState(false);
  
  const [btnBool, setBtnBool] = useState(false);
  const [btnText, setBtnText] = useState("获取验证码");
  const [count, setCount] = useState(0);
  
  const phoneOnchanged = (e) => {
    setPhoneValidate(false);
    setPhone(e.target.value);
  };

  const codeOnchanged = (e) => {
    setCodeValidate(false);
    setCode(e.target.value);
  };

  const sms = useSMS(phone);
  const getSMS = async () => {
    if (/^1\d{10}$/.test(phone)) {
      setBtnBool(true);
      setCount(60);
      sms().then((res) => {
        if (res === false) {
          setPhoneValidate(true);
        }
      });
    } else {
      setPhoneValidate(true);
    }
  };

  const login = useLogin(phone, code)
  const handleConfirm = () => {
    if (/^1\d{10}$/.test(phone)) {
      if (checked === true) {
        login().then((res) => {
          if (res === false) {
            setCodeValidate(true);
          } else {
            handler();
          }
        })
      }
    } else {
      setPhoneValidate(true);
    }
  };

  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (count > 1) {
      setBtnText(count +"s");
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
    if (count === 1) {
      setBtnText(count +"s");
      setTimeout(() => {
        setBtnText("获取验证码");
        setCount(0);
        setBtnBool(false);
      }, 1000);
    }
  }, [count]);

  const [agreementOpen, setAgreementOpen] = useState(false);
  
  return (
    <div className="relative login">

      <img className="close-button" onClick={toClose} src={close} />

      <div className="title-container flex flex-col items-center">
        <div className="title" >用户注册/登录</div>
        <div className="subtitle" style={{color: "#323232", opacity: "0.8"}}>未注册过的手机号将自动创建账号</div>
      </div>
      
      <div className="flex phone">
        <div className="flex flex-auto flex-col">
          <input className="phone flex-auto" type="tel" size="1" onChange={phoneOnchanged} placeholder="请输入手机号"/>
          <div className="validate">
            {phoneValidate && "请输入正确的手机号"}
          </div>
        </div>
      </div>

      <div className="flex code">
        <div className="flex flex-auto flex-col">
          <input className="code" type="tel" size="1" onChange={codeOnchanged} placeholder="请输入验证码"/>
          <div className="validate">
            {codeValidate && "验证码不正确"}
          </div>
        </div>
        <button onClick={getSMS} disabled={btnBool} className="login code font-sm">
          {btnText}
        </button>
      </div>

      <div className="agreement-container w-full flex justify-center">
        <Checkbox onChange={handleChange} sx={{  padding: "0", '& .MuiSvgIcon-root': { fontSize: "4.5333vw", color: "#F9914F" } }} />
        <div className="agreement-label flex items-center"><span>我已阅读并同意</span><div onClick={()=>{setAgreementOpen(true)}} className="relative" style={{color:"#F9914F"}}>《用户协议》</div></div>
      </div>

      <div className="flex justify-center mb-8 relative">
        <button className="login next relative" onClick={handleConfirm} disabled={!checked}>登录</button>
      </div>

      <Drawer anchor="bottom" open={agreementOpen} onClose={()=>{setAgreementOpen(false)}}
          sx={{ "& .MuiDrawer-paper": { background: "white", borderRadius: "30px 30px 0px 0px" } }} >
          <Agreement handleClose={()=>{setAgreementOpen(false)}}/>
        </Drawer>
    </div>
  );
}