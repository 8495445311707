import { portalAddress, AuthContext } from "../App";
import { useContext, useState } from "react";

export default function useLogout() {
  const { state, dispatch } = useContext(AuthContext);

  const post = async() => {
    let headers = new Headers();
    headers.append("accessToken", state.accessToken);
    try {
      const response = await fetch(
        portalAddress + "/user/logout", 
        {
          method: "POST",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        dispatch({
          type: "LOGOUT",
        });
        return true;
      } else {
        console.log(json.message);
        return false;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  return post;
}