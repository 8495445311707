import "./About.css";
import about from "../assets/about/about.svg";
import MenuBar from "../components/MenuBar";
import headline from "../assets/home/01.svg";

export default function About() {
  return (
    <div className="about">
      <MenuBar/>
      <div className="main">
        <img className="headline" src={headline} alt="headline"/>
        <div className="content-container flex flex-col items-center">
          <div><img className="about-img" src={about} /></div>
          <div className="content">
            <p>CIRCUS BOY BAND （简称CBB）是由韩国设计师O Hyunsuk和LEE Jun-yong在2011年成立的设计师品牌，他们将日常的经验和想象中的 图像结合在一起，以插画、平面设计以及产品设计形式传达故事。他们认为人生就像马戏，含有喜怒哀乐，希望像马戏一样创造出令人惊喜的产品。 </p>
            <p>渝北吾悦广场携手幸会潮玩签约代理设计师品牌 CIRCUS BOY BAND ，以潮流艺术公园定位，通过潮玩灵感共创重庆限定数字藏品，将剧场系列手办Horangi与重庆潮流交互融合，为潮玩圈发布独家限定数字藏品。 </p>
            <p>数字藏品具有作品的唯一性、不可分割性以及不可被篡改的特点，此商品具有特殊性，因此不支持退换，谢谢您的理解与配合！  </p>
            <p>本网站仅代为展示和提取数字藏品创意作品，严格禁止自然人、法人或非法人组织利用数字藏品创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。 </p>
            <p>领取细则</p>
            <p>一、	适用对象：领取码用户（指拥有有效数字藏品领取卡的用户，“有效”指该领取卡对应的领取码未使用过）使用领取码在重庆渝北吾悦广场数字藏品发行站点完成验证并获得数字藏品。每个领取码仅可对应一次重庆限定款数字藏品领取机会，完成验证后即失效。</p>
            <p>二、	领取方式：领取码用户需要在站点进行验证并授权绑定手机号作为领取数字藏品以及提取数字藏品的唯一凭证。特别提示：用户的手机号及领取码请务必自行妥善保管。因用户个人原因泄露、遗失、转号而导致数字藏品遗失或被盗或冒领的后果及损失由该用户自行承担。</p>
            <p>三、	领取码用户在本站点领取数字藏品后，可通过两种途径查看所持有的数字藏品：路径1：本站点-首页-个人中心-我的数字藏品。该查看路径会保留一年，至2022年11月23日20:00:00关闭。路径2：通过conflux浏览器（地址：confluxscan.net）查询数字藏品的智能合约地址来浏览所持有的数字藏品。</p>
            <p>四、	领取码用户在2021年02月14日后，可将已领取的数字藏品提取至个人的区块链钱包。具体提取时间段为：2022年02月14日 00:00 - 2022年11月23日20:00:00。用户不得对所持有的数字藏品进行转赠、售卖、或以其它任何形式进行转让。 用户在提取后需妥善保管自己的数字藏品和自己的区块链钱包私钥。提取后发生的一切遗失、失窃、无法找回等情形，均由用户自行承担。如果中奖用户不提取中国航天数字藏品至自己的个人钱包，用户依旧可将中国航天数字藏品保管在本站点。 关于如何创建conflux区块链钱包和中国航天数字藏品提取, 具体请根据conflux网站（地址：https://portal.confluxnetwork.org/）公布的指导操作。</p>
            <p>五、	领取码用户领取数字藏品后，利用该数字藏品进行的违约、违法行为，由用户个人承担相关法律责任。</p>
          </div>
        </div>
      </div>
    </div>
  )
}