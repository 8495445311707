import { useState } from "react";
import useClaim from "../utils/useClaim";
import { useNavigate } from "react-router-dom";
import { appId } from "../App";
import "./Claim.css";
import closeButton from "../assets/claim/close.svg";
import loading from "../assets/claim/loading.gif";
import fail from "../assets/claim/fail.png";
import nftImg from "../assets/claim/nft.png";
import qrcode from "../assets/claim/qrcode.svg";

export default function Claim({handleClose}) {
  const [giftCode, setGiftCode] = useState();
  const [codeValidate, setCodeValidate] = useState(false);
  const [claimState, setClaimState] = useState();
  const post = useClaim();
  const [nft, setNFT] = useState();
  const navigate = useNavigate();
  const getNFT = () => {
    setClaimState("loading");
    post(giftCode).then((res) => {
      if (res === "fail" || res === "wrong" || res === "used") {
        setClaimState(res);
      } else {
        setNFT(res);
        setClaimState("success");
      }
    })
  }
  const validiteCode = (code) => {
    if (code.length > 7) {
      setCodeValidate(true);
    } else {
      setCodeValidate(false);
    }
  }
  const codeOnchange = (e) => {
    setGiftCode(e.target.value);
    validiteCode(e.target.value);
  }
  const share = (e) => {
    navigate("/poster/" + nft.tokenId)
  }
  const detail = (e) => {
    navigate("/nft/" + nft.tokenId)
  }

  switch (claimState) {
    case "loading":
      return(
        <div className="loading">
          <div>
            <div className="loading-title">
              <img className="loading-img" src={loading} />
            </div>
            <div className="loading-content">
              <div className="input-tip">加载中,请耐心等待...</div>
            </div>
          </div>
        </div>
      )
    case "success":
      return(
        <div className="success">
          <div className="nft-container">
            <img className="nft" src={nftImg} />
          </div>
          <div className="congrats-container">渝北潮玩稀有款数字藏品是为渝北吾悦广场庆典准备的数字礼品。成功领取的用户可在12月25日后于吾悦广场小程序登录查看</div>
          <img className="qrcode" src={qrcode} />
          <div className="control">
            <button className="share" onClick={share}>
              去分享
            </button>
            <button className="to-detail" onClick={detail}>
              查看详情
            </button>
          </div>
          <button className="close" onClick={handleClose} >
            <img src={closeButton} />
          </button>
        </div>
      )
    case "fail":
      return(
        <div className="fail">
          <img className="fail-img" src={fail} />
          <div className="content">由于网络原因领取失败，<span>请保管好领取卡～</span></div>
          <div className="control">  
            <button className="try-now" onClick={getNFT}>
              立即重试
            </button>
            <button className="try-later" onClick={handleClose}>
              稍后再试
            </button>
          </div>
          <button className="close" onClick={handleClose} >
            <img src={closeButton} />
          </button>
        </div>
      )
      case "wrong":
        return(
          <div className="fail">
            <img className="fail-img" src={fail} />
            <div className="content">领取码错误<span>请重新输入</span></div>
            <div className="control">  
              <button className="re-input" onClick={()=>{setClaimState("toClaim")}}>
                重新输入
              </button>
            </div>
            <button className="close" onClick={handleClose} >
              <img src={closeButton} />
            </button>
          </div>
        )
      case "used":
        return(
          <div className="fail">
            <img className="fail-img" src={fail} />
            <div className="content">领取码已失效<span>请重新输入</span></div>
            <div className="control">  
              <button className="re-input" onClick={()=>{setClaimState("toClaim")}}>
                重新输入
              </button>
            </div>
            <button className="close" onClick={handleClose} >
              <img src={closeButton} />
            </button>
          </div>
        )
    default:
      return (
        <div className="claim">
          <div>
            <div className="title">
              <div>领取<br />数字藏品</div>
            </div>
            <div className="subtitle">
              <div>请输入领取卡背后的8位领取码</div>
            </div>
            <div className="content">
              <input onChange={codeOnchange} />
            </div>
          </div>
          <div className="control">
            <button className="confirm" onClick={getNFT} disabled={!codeValidate}>
              下一步
            </button>
            <button className="close" onClick={handleClose} >
              <img src={closeButton} />
            </button>
          </div>
        </div>
      )
  }
}