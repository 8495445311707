import { portalAddress } from "../App";
import { useState } from "react";

export default function useSMS(phone) {
  const [result, setResult] = useState();

  const post = async () => {
    try {
      const response = await fetch(
        portalAddress + "/user/sendSms", 
        {
          method: "POST",
          body: JSON.stringify({ phone: phone }),
        }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        return true;
      } else {
        console.log(json.message);
        return false;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  return post;
}