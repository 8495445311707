import { serverAddress, AuthContext, appId } from "../App";
import { useContext } from "react";

export default function useClaim() {
  const { state } = useContext(AuthContext);
  const post = async(giftCode) => {
    let headers = new Headers();
    headers.append("appId", appId);
    headers.append("accessToken", state.accessToken);
    try {
      const response = await fetch(
        serverAddress + "/nft/get-prize",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ giftCode: giftCode }),
        }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        return json.data;
      } else if (json.returnCode === "20016") {
        return "wrong";
      } else if (json.returnCode === "20017") {
        return "used";
      } else {
        console.log(json.message);
        return "fail";
      }
    } catch (error) {
      console.log("error", error);
      return "fail";
    }
  }

  return post;
}